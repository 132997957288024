* {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
  padding: 0;
}

.display-controls {
  text-align: center;
  color: #f18a00;
  width: 100%;
  font-size: 4em;
  display: block;
  position: absolute;
  bottom: 10%;
}

.display-controls .controller-left, .display-controls .controller-right {
  vertical-align: middle;
  cursor: pointer;
  margin: 0 100px;
}

.display-controls .controller-left {
  transform: rotate(180deg);
}

.controller-info {
  color: #243746;
  text-align: center;
  width: 100%;
  font-size: 1.5em;
  display: block;
  position: absolute;
  bottom: 5%;
}

#HIGHSCORE {
  width: 250px;
  margin-left: -175px;
  font-family: lineariconsregular;
  position: absolute;
  left: 50%;
  overflow: visible;
}

#container {
  color: #232323;
  text-align: center;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tweet {
  color: #4099ff;
  font-size: 2rem;
}

@media only screen and (device-width >= 320px) and (device-width <= 480px) {
  .tweet {
    font-size: 1rem;
  }
}

a {
  color: #232323;
}

.navbar {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding: 2.5vmin 0;
}

.navbar li {
  text-align: center;
  width: calc(33% - 1.25vmin);
  display: inline-block;
}

#title {
  color: #ecf0f1;
  font-size: 6vmin;
}

#score {
  text-align: center;
  width: 30%;
  font-size: 4vmin;
}

#highscores {
  text-align: center;
  list-style: none;
}

.not-visible {
  display: none;
}

button {
  color: #ecf0f1;
  background-color: #0000;
  border: 0;
}

button:focus {
  outline: 0;
}

#openSideBar {
  z-index: 3003;
  color: #232323;
  cursor: pointer;
  width: 72px;
  height: 72px;
  margin-top: 6px;
  margin-left: 11px;
  position: fixed;
  top: 0;
  left: 0;
}

#pauseBtn {
  z-index: 3000;
  color: #232323;
  cursor: pointer;
  z-index: 99;
  width: 72px;
  height: 72px;
  margin-bottom: 7px;
  margin-right: 11px;
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
}

#restartBtn {
  z-index: 3000;
  color: #232323;
  cursor: pointer;
  z-index: 99;
  width: 72px;
  height: 72px;
  margin-bottom: 7px;
  margin-left: 11px;
  display: none;
  position: fixed;
  bottom: 0;
}

#startBtn {
  z-index: 99999999;
  cursor: pointer;
  width: 25%;
  height: 25%;
  display: none;
  position: absolute;
  top: 38%;
  left: 40%;
}

#helpScreen {
  z-index: 3001;
  text-align: center;
  color: #232323;
  background-color: #ecf0f1;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 15px;
  display: none;
  position: fixed;
  top: 0;
}

#colorBlindBtn {
  z-index: 9999;
  text-align: center;
  color: #232323;
  cursor: pointer;
  width: 120px;
  height: 20px;
  font-size: 15px;
  position: absolute;
  top: 65%;
  left: 47%;
}

#inst_main_body {
  text-align: left;
  max-width: 400px;
  padding: 0;
  font-size: 1.2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#inst_main_body > a {
  font-weight: bold;
}

#inst_main_body > hr {
  color: #232323;
  background-color: #232323;
  border: 0;
  width: 100%;
  height: 1px;
}

#inst_main_body > p {
  margin-bottom: 15px;
}

#instructions_head {
  text-align: center;
  padding-bottom: 4px;
  font-size: 1.5em;
}

#highScoreInGameTextHeader {
  color: #bdc3c7;
  width: 100%;
}

#highScoreInGameText {
  text-align: center;
  width: 200px;
  margin-left: -100px;
  position: absolute;
  top: 10px;
  left: 50%;
}

#currentHighScore {
  color: #2c3e50;
  width: 100%;
  margin-top: -5px;
  font-size: 2em;
}

@media (device-width <= 480px) {
  #helpScreen {
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 20px;
    font-size: 13px;
    position: fixed;
    top: 2px;
  }

  #inst_main_body {
    width: calc(100% - 70px);
    transform: translate(-50%, -50%);
  }

  #instructions_head {
    padding-left: 0%;
  }

  #openSideBar, #pauseBtn, #restartBtn {
    width: 60px;
    height: 60px;
  }
}

.overlay {
  pointer-events: none;
  z-index: 3000;
  color: #232323;
  text-align: center;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay .centeredHeader {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
}

.helpText {
  z-index: 3001;
}

.centeredHeader {
  text-align: center;
  font-size: 5vw;
  line-height: 4rem;
}

.centeredSubHeader {
  text-align: center;
  font-size: 2.2rem;
  line-height: 1em;
}

.centeredSubSubHeader {
  text-align: center;
  font-size: 1.4rem;
}

#buttonCont {
  width: 310px;
  margin-left: -8px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

@media (orientation: landscape) {
  #buttonCont {
    bottom: calc(31vh - 36px);
  }

  #container {
    top: 39vh;
  }
}

@media (orientation: portrait) {
  #buttonCont {
    bottom: calc(50vh - 19vw - 36px);
  }

  #container {
    top: calc(50vh - 11vw);
  }
}

@media (device-width >= 320px) and (device-width <= 600px) {
  .centeredHeader {
    text-align: center;
    font-size: 2rem;
    line-height: .6rem;
  }

  .centeredSubHeader {
    text-align: center;
    font-size: 1rem;
  }

  .overlay {
    margin-top: -20%;
  }

  #buttonCont {
    width: 280px;
  }
}

@media (device-width >= 320px) and (device-width <= 600px) and (orientation: landscape) {
  #buttonCont {
    bottom: 119px;
  }
}

@media (device-width >= 320px) and (device-width <= 600px) and (orientation: portrait) {
  #buttonCont {
    bottom: 119px;
  }
}

.blur {
  opacity: .1;
}

* {
  margin: 0;
  padding: 0;
}

#clickToExit {
  cursor: pointer;
}

#canvas {
  z-index: 0;
  background: none;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: absolute;
  left: 0;
  right: 0;
}

#title {
  z-index: 99;
  color: #2c3e50;
  margin-top: 20px;
}

#restart {
  margin: 0 0 -3.5px;
  padding: 0;
  display: none;
  position: absolute;
  bottom: 15px;
  left: calc(50% - 124px);
  transform: translate(-50%);
  cursor: pointer !important;
}

#socialShare {
  margin: 0 0 6.5px;
  padding: 0 0 2px;
  display: none;
  position: absolute;
  bottom: 0;
  left: calc(50% + 30px);
  transform: translate(-50%);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

#afterhr {
  margin: 0 0 -10px;
  padding: 0 0 -10px;
}

body {
  overflow: hidden;
}

#gameoverscreen {
  padding: 8px;
}

.GOTitle {
  font-size: 24.2px;
  font-weight: bold;
}

.scoreNum {
  font-weight: normal !important;
}

#cScore {
  font-size: 60.5px;
  font-weight: light;
  margin-top: -10px;
  margin-bottom: 5px;
}

#highScoresTitle {
  margin-bottom: 2px;
}

.score {
  font-weight: light;
  line-height: 18px;
}

#bottomContainer {
  width: 100%;
  position: absolute;
  bottom: 0;
}

#androidBadge {
  height: 41px;
  margin-top: 5px;
  position: absolute;
  left: calc(50% - 66px);
  transform: translate(calc(-50% - 4px), -1px);
}

#iOSBadge {
  margin-top: 5px;
  position: absolute;
  left: calc(50% + 65px);
  transform: translate(calc(-50% - 4px));
}

#authors {
  width: 200px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

/*# sourceMappingURL=index.0631dca8.css.map */
