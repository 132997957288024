* {
	user-select: none;
	padding: 0;
	margin: 0;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.display-controls {
	display: block;
    position: absolute;
    bottom: 10%;
    font-size: 4em;
    width: 100%;
	text-align: center;
	color: #f18a00;
}

.display-controls .controller-left,
.display-controls .controller-right {
    vertical-align: middle;
	cursor: pointer;
	margin: 0 100px;
}

.display-controls .controller-left {
	transform: rotate(180deg);
}

.controller-info {
	display: block;
    font-size: 1.5em;
    color: #243746;
    position: absolute;
    bottom: 5%;
    text-align: center;
    width: 100%;
}

#HIGHSCORE {
	position: absolute;
	left: 50%;
	width: 250px;
	overflow: visible;
	margin-left: -175px;
	font-family: "lineariconsregular";
}

#container {
	color: #232323;
	position: absolute;
	left: 50%;
	top: calc(50%);
	text-align: center;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	display: none;
}

.tweet {
	font-size: 2rem;
	color: #4099FF;
}

@media only screen and (min-device-width:320px) and (max-device-width:480px) {
	.tweet {
		font-size: 1rem;
	}
}

a {
	color: #232323;
}

.navbar {
	width: 80%;
	margin: 0 auto;
	padding: 2.5vmin 0;
	text-align: center;
}

.navbar li {
	display: inline-block;
	text-align: center;
	width: calc(33% - 1.25vmin);
}

#title {
	color: #ecf0f1;
	font-size: 6vmin;
}

#score {
	font-size: 4vmin;
	text-align: center;
	width: 30%;
}

#highscores {
	text-align: center;
	list-style: none;
}

.not-visible {
	display: none;
}

button {
	color: #ecf0f1;
	background-color: rgba(0, 0, 0, 0);
	border: 0;
}

button:focus {
	outline: 0;
}

#openSideBar {
	position: fixed;
	margin-top: 6px;
	width: 72px;
	height: 72px;
	margin-left: 11px;
	z-index: 3003;
	color: #232323;
	cursor: pointer;
	top: 0;
	left: 0;
}

#pauseBtn {
	display: none;
	position: fixed;
	margin-bottom: 7px;
	width: 72px;
	height: 72px;
	margin-right: 11px;
	z-index: 3000;
	color: #232323;
	cursor: pointer;
	right: 0;
	bottom: 0;
	z-index: 99;
}

#restartBtn {
	display: none;
	position: fixed;
	margin-bottom: 7px;
	width: 72px;
	height: 72px;
	margin-left: 11px;
	z-index: 3000;
	color: #232323;
	cursor: pointer;
	bottom: 0;
	z-index: 99;
}

#startBtn {
	position: absolute;
	left: 40%;
	top: 38%;
	height: 25%;
	width: 25%;
	z-index: 99999999;
	display: none;
	cursor: pointer;
}

#helpScreen {
	height: 100%;
	width: 100%;
	z-index: 3001;
	position: fixed;
	top: 0;
	margin: 0;
	font-size: 15px;
	text-align: center;
	display: none;
	color: #232323;
	background-color: rgba(236, 240, 241, 1);
}

#colorBlindBtn {
	position: absolute;
	width: 120px;
	height: 20px;
	z-index: 9999;
	left: 47%;
	top: 65%;
	font-size: 15px;
	text-align: center;
	color: #232323;
	cursor: pointer;
}

#inst_main_body {
	padding: 0px;
	font-size: 1.2em;
	left: 50%;
	top: 50%;
	position: absolute;
	text-align: left;
	max-width: 400px;
	/*margin-top:30px;*/
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}

#inst_main_body > a {
	font-weight: bold;
}

#inst_main_body > hr {
	width: 100%;
	color: #232323;
	background-color: #232323;
	height: 1px;
	border: 0;
}

#inst_main_body > p {
	margin-bottom: 15px;
}

#instructions_head {
	text-align: center;
	font-size: 1.5em;
	padding-bottom: 4px;
}

#highScoreInGameTextHeader {
	color: #bdc3c7;
	width: 100%;
}

#highScoreInGameText {
	position: absolute;
	top: 10px;
	text-align: center;
	width: 200px;
	left: 50%;
	margin-left: -100px;
}

#currentHighScore {
	color: #2c3e50;
	width: 100%;
	margin-top: -5px;
	font-size: 2em;
}

@media (max-device-width:480px) {
	#helpScreen {
		position: fixed;
		top: 2px;
		padding-top: 20px;
		margin: 0;
		width: 100%;
		height: 100%;
		font-size: 13px;
		text-align: center;
	}
	#inst_main_body {
		width: calc(100% - 70px);
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
	}
	#instructions_head {
		padding-left: 0%;
	}
	#openSideBar {
		width: 60px;
		height: 60px;
	}
	#pauseBtn {
		width: 60px;
		height: 60px;
	}
	#restartBtn {
		width: 60px;
		height: 60px;
	}
}

.overlay {
	pointer-events: none;
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;

	z-index: 3000;
	color: #232323;
	text-align: center;

	display: flex;
    flex-flow: row;
    justify-content: center;
	align-items: center;


	.centeredHeader {
		// background-color: rgba(250,250,250,0.70);
		padding: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}

.helpText {
	z-index: 3001;
}

.centeredHeader {
	font-size: 5vw;
	text-align: center;
	line-height: 4rem;
}

.centeredSubHeader {
	font-size: 2.2rem;
	line-height: 1em;
	text-align: center;
}

.centeredSubSubHeader {
	font-size: 1.4rem;
	text-align: center;
}

#buttonCont {
	position: absolute;
	left: 50%;
	width: 310px;
	margin-left: -8px;
	transform: translate(-50%, 0%);
	-webkit-transform: translate(-50%, 0%);
	-moz-transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
}

@media (orientation:landscape) {
	#buttonCont {
		bottom: calc(50vh - 19vh - 36px);
	}
	#container {
		top: calc(50vh - 11vh);
	}
}

@media (orientation:portrait) {
	#buttonCont {
		bottom: calc(50vh - 19vw - 36px);
	}
	#container {
		top: calc(50vh - 11vw);
	}
}

@media (min-device-width:320px) and (max-device-width:600px) {
	.centeredHeader {
		font-size: 2rem;
		text-align: center;
		line-height: 0.6rem;
	}
	.centeredSubHeader {
		font-size: 1rem;
		text-align: center;
	}
	.overlay {
		margin-top: -20%;
	}
	#buttonCont {
		width: 280px;
	}
	@media (orientation:landscape) {
		#buttonCont {
			bottom: 119px;
		}
	}
	@media (orientation:portrait) {
		#buttonCont {
			bottom: 119px;
		}
	}
}

.blur {
	opacity: 0.1;
}

* {
	padding: 0;
	margin: 0;
}

body {
	/* color: #ecf0f1; */
	/* background-color: #fff; */
}

#clickToExit {
	cursor: pointer;
}

#canvas {
	transition: all 0.5s ease;
	position: absolute;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	background: transparent;
	z-index: 0;
}

#title {
	z-index: 99;
	margin-top: 20px;
	color: #2c3e50;
}

#gameoverscreen {
	/*display:none;*/
}

#restart {
	display: none;
	cursor: pointer !important;
	margin: 0;
	padding: 0;
	bottom: 15px;
	position: absolute;
	left: calc(50% - 124px);
	transform: translate(-50%, 0%);
	-webkit-transform: translate(-50%, 0%);
	-moz-transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
	margin-bottom: -3.5px;
}

#socialShare {
	display: none;
	margin: 0;
	padding: 0;
	bottom: 0;
	margin-bottom: 6.5px;
	position: absolute;
	left: calc(50% + 30px);
	transform: translate(-50%, 0%);
	-webkit-transform: translate(-50%, 0%);
	-moz-transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
	padding-bottom: 2px;
}

.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#afterhr {
	margin: 0;
	padding: 0;
	margin-bottom: -10px;
	padding-bottom: -10px;
}

body {
	overflow: hidden;
}

#gameoverscreen {
	padding: 8px;
}

.GOTitle {
	font-weight: bold;
	font-size: 24.2000px;
}

.scoreNum {
	font-weight: normal ! important;
}

#cScore {
	font-size: 60.5000px;
	font-weight: light;
	margin-bottom: 5px;
	margin-top: -10px;
}

#highScoresTitle {
	margin-bottom: 2px;
}

.score {
	font-weight: light;
	line-height: 18px;
}

#bottomContainer {
	position: absolute;
	width: 100%;
	bottom: 0px;
}

#androidBadge {
	position: absolute;
	height: 41px;
	margin-top: 5px;
	left: calc(50% - 66px);
	transform: translate(calc(-50% - 4px), -1px);
	-webkit-transform: translate(calc(-50% - 4px), -1px);
	-moz-transform: translate(calc(-50% - 4px), -1px);
	-ms-transform: translate(calc(-50% - 4px), -1px);
}

#iOSBadge {
	margin-top: 5px;
	position: absolute;
	left: calc(50% + 65px);
	transform: translate(calc(-50% - 4px), 0%);
	-webkit-transform: translate(calc(-50% - 4px), 0%);
	-moz-transform: translate(calc(-50% - 4px), 0%);
	-ms-transform: translate(calc(-50% - 4px), 0%);
}

#authors {
	position: absolute;
	width: 200px;
	bottom: 0px;
	left: 50%;
	transform: translate(-50%, 0%);
	-webkit-transform: translate(-50%, 0%);
	-moz-transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
}